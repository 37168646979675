<template>
  <b-form @submit.stop.prevent="confirm">
    <b-modal :id="`importModal_${id}`" centered size="md" @hide="close">
      <template #modal-title> {{ title }} </template>
      <div :disabled="loading">
        <p>
          Toplu şekilde yükleme ya da güncelleme yapmak isterseniz dosya
          ekleyerek bunu gerçekleştirebilirsiniz. Sadece <b>csv</b>,
          <b>xls</b> ve <b>xlsx</b> dosya formatları geçerlidir. Maksimim dosya
          boyutu <b>25 MB</b> olmalıdır.
        </p>

        <p class="mt-3 mb-5">
          Hazır bir dosyanız yok ise
          <u
            class="font-weight-bolder cursor-pointer"
            @click="$emit('downloadSampleFile')"
            >buradan</u
          >
          şablon dosyasını indirerek doldurabilirsiniz.
        </p>

        <b-form-group class="mb-0">
          <b-form-file
            id="file"
            ref="file"
            v-model="files"
            class="rounded-sm"
            :state="validateState('files')"
            accept=".csv, .xls, .xlsx"
            placeholder="Dosya seçiniz"
            drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
          ></b-form-file>
        </b-form-group>

        <p class="font-size-xs mx-3">
          Sadece <b>csv</b>, <b>xls</b> ve <b>xlsx</b> dosya formatları
          geçerlidir. Maksimim dosya boyutu <b>25 MB</b> olmalıdır.
        </p>

        <slot></slot>
      </div>
      <template #modal-footer>
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button
            type="reset"
            variant="link"
            @click="close"
            class="text-dark text-hover-dark-50 text-decoration-none"
            >İptal</b-button
          >

          <b-button
            type="submit"
            variant="primary"
            class="ml-auto"
            ref="submitButton"
            @click="confirm"
            >{{ confirmLabel }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ImportModal',
  mixins: [validationMixin],
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    importApiEndpoint: {
      type: String,
      default: '',
    },
    show: Boolean,
    title: {
      type: String,
      default: 'Dosyadan Yükle',
    },
    resetForm: Boolean,
    confirmLabel: {
      type: String,
      default: 'YÜKLE',
    },
  },
  data() {
    return {
      loading: false,
      submitButton: null,
      files: [],
    };
  },
  validations: {
    files: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    async confirm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      this.submitButton = this.$refs['submitButton'];
      this.$spinnerLoader.addSpinnerLoader(this.submitButton);
      this.loading = true;

      if (this.importApiEndpoint === '') {
        this.$generateNotification(
          this,
          'warning',
          'İşlem yapılacak adres getirilemedi!'
        );
      } else if (this.files.length === 0) {
        this.$generateNotification(
          this,
          'danger',
          'Lütfen geçerli bir dosya seçiniz.'
        );
      } else {
        let selectedFile = this.files;
        let base64 = await this.toBase64(selectedFile);
        var payload = {
          fileName: selectedFile.name,
          fileContent: base64,
        };

        // let formData = new FormData();
        // formData.append('file', this.form.files, this.form.files.name);

        this.$dbFunctions
          .insertOrUpdate(this.importApiEndpoint, '', payload)
          .then((res) => {
            this.$dbFunctions.setResponse(this, res);
            this.$emit('updateList');
          })
          .catch((err) => {
            this.$dbFunctions.setResponse(
              this,
              err,
              'Tamamlanamayan kayıtlar var.',
              '',
              'warning'
            );
            //Sadece bazı kayıtlarda hata işlemini açtığımızdan, her durumda bunu yapmak gerekiyor.
            this.$emit('updateList');
          })
          .finally(() => {
            this.$spinnerLoader.removeSpinnerLoader(this.submitButton);
            this.formReset();
            this.loading = false;
            this.close();
          });
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        //reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });
    },
    formReset() {
      this.files = [];
      this.$v.$reset();
    },
    close() {
      this.$bvModal.hide(`importModal_${this.id}`);
      this.$emit('closeDetail');
    },
  },
  watch: {
    resetForm(newVal) {
      if (newVal) {
        this.submitButton = this.$refs['submitButton'];
        this.$spinnerLoader.removeSpinnerLoader(this.submitButton);
        this.loading = false;
      }
    },
    show(newVal) {
      if (newVal) {
        this.$bvModal.show(`importModal_${this.id}`);
      } else this.$bvModal.hide(`importModal_${this.id}`);
    },
  },
};
</script>
